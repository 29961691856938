import React from 'react';
import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import { data } from 'data/solutions/cards';
import Card from '../card';

const GridContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, auto);
  grid-gap: 40px;
  padding-bottom: 0;
  ${media.desktop} {
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    text-align: center;
  }
  ${media.tablet} {
    padding-top: 80px;
    padding-bottom: 75px;
  }
  ${media.mobile} {
    grid-gap: 24px;
    padding-bottom: 45px;
  }
`;

const SolutionCards = () => {
  return (
    <GridContainer>
      {data.map((card) => (
        <Card {...card} key={card.title} />
      ))}
    </GridContainer>
  );
};

export default SolutionCards;

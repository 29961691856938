import React from 'react';
import map from 'lodash/map';
import styled from 'styled-components';
import { media } from 'theme/breakpoints';
import Layout from 'layout';
import Button from 'components/ui/button';
import StartUsing from 'components/ui/startUsing';
import Logos from 'components/ui/trustedBy';
import StartUsingCTA from 'components/index/startusingCTA';
import Cards from 'components/solutions/cards';
import Section from 'components/solutions/section';
import { data } from 'data/solutions/sections';
import { Section as FeatureSection } from 'components/solutions/section/styled';

const StyledSection = styled.section`
  margin-top: 4rem;
  padding-bottom: 0;
  & .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  & h1 {
    text-align: center;
  }
  & .subtitle {
    margin: 32px 0 48px;
    font-size: 22px;
    text-align: center;
  }
  ${media.mobile} {
    margin-top: 1rem;
    ${FeatureSection}:last-child {
      padding-bottom: 95px;
    }
    & .subtitle {
      font-size: 18px;
      margin: 32px 0 40px;
    }
  }
`;

const Solutions = () => {
  return (
    <Layout
      metaTitle="Solutions | Session Recordings and Qualitative Analytics"
      metaDescription="See how LiveSession can help with conversion optimization, usability testing, debugging, customer support, and more. Try a new qualitative analytics tool."
      canonical="/solutions/"
    >
      <StyledSection>
        <div className="container">
          <h1 className="line">
            <span>Smart ways</span> to use LiveSession
          </h1>
          <p className="subtitle">One product, different solutions</p>
          <Button signUp secondary center sourceID="hero">
            Sign up free
          </Button>
          <Cards />
          <Logos hero style={{ paddingBottom: 75 }} />
          {map([data[0], data[1], data[2]], (section, idx) => (
            <Section {...section} idx={idx + 1} key={idx} />
          ))}
          <StartUsingCTA />
          {map([data[3], data[4], data[5]], (section, idx) => (
            <Section {...section} idx={idx} key={idx} />
          ))}
        </div>
      </StyledSection>
      <StartUsing />
    </Layout>
  );
};

export default Solutions;
